<template>
  <ProfileMain />
</template>

<script>
import ProfileMain from '@/components/sidebar/ProfileMain';

export default {
  name: 'Profile',
  components: {
    ProfileMain
  }
}
</script>

<style>

</style>