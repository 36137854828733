<template>
  <section class="block general flex">
    <div class="left">
      <label>
        <div class="avatar">
          <img v-if="user.photo && !preview_file" :src="user.photo" alt="">
          <img v-if="preview_file" :src="preview_file" alt="">
        </div>
        <input type="file" ref="file" name="recfile" @change="uploadFile">
      </label>
    </div>
    <div class="right">
      <div class="profile__info">
        <div class="input__block" v-for="input of inputs" :key="input.id" :style="input.styles">
          <span>{{ input.name }}</span>
          <input v-if="input.mask" :disabled="!editMode" :maxlength="input.maxlength" v-mask="input.mask" type="text" :placeholder="input.placeholder" v-model="user[input.id]">
          <input v-else type="text" :disabled="!editMode" :maxlength="input.maxlength" :placeholder="input.placeholder" v-model="user[input.id]">
        </div>
      </div>
      <div class="change__password" v-if="editMode">
        <div class="title">Сменить пароль</div>
        <div class="input__block" v-for="input of pass_inputs" :key="input.id" :style="input.styles">
          <span>{{ input.name }}</span>
          <input v-if="input.mask" v-mask="input.mask" type="password" :maxlength="input.maxlength" :placeholder="input.placeholder" v-model="user[input.id]">
          <input v-else type="password" :placeholder="input.placeholder" :maxlength="input.maxlength" v-model="user[input.id]">
        </div>
      </div>
      <button v-if="!editMode" @click="changeEditMode" class="btn btn-gradient">Редактировать</button>
      <button v-else class="btn btn-gradient" :class="{pending: pending}" @click="saveData">Сохранить</button>
    </div>
  </section>
</template>

<script>
import Axios from 'axios';
export default {
  name: 'ProfileMain',
  data() {
    return {
      editMode: false,
      pending: false,
      file: '',
      preview_file: null,
      user: this.$store.getters.user,
      password: {
        old: '',
        new: '',
        replace: ''
      },
      inputs: null,
      pass_inputs: null,
    }
  },
  mounted() {
    this.inputs = [
      {id: 'surname', name: 'Фамилия', placeholder: 'Фамилия', value: this.user.surname, maxlength: 20, styles: 'grid-column: 1 / 3'},
      {id: 'login', name: 'Логин', placeholder: 'Логин', value: this.user.login, maxlength: 20, styles: 'grid-column: 3 / 5'},
      {id: 'name', name: 'Имя', placeholder: 'Имя', value: this.user.name, maxlength: 20, styles: 'grid-column: 1 / 3'},
      {id: 'email', name: 'E-mail', placeholder: 'E-mail', value: this.user.email, maxlength: 30, styles: 'grid-column: 3 / 5'},
      {id: 'patronymic', name: 'Отчество', placeholder: 'Отчество', value: this.user.patronymic, maxlength: 20, styles: 'grid-column: 1 / 3'},
    ];
    this.pass_inputs = [
      {id: 'old', name: 'Текущий пароль', placeholder: '*************************', value: this.password.old, maxlength: 20, styles: 'grid-column: 1 / 3'},
      {id: 'new', name: 'Новый пароль', placeholder: '*************************', value: this.password.new, maxlength: 20, styles: 'grid-column: 1 / 3'},
      {id: 'replace', name: 'Повторите новый пароль', placeholder: '*************************', value: this.password.replace, maxlength: 20, styles: 'grid-column: 1 / 3'},
    ]
  },
  methods: {
    changeEditMode() {
      this.editMode = !this.editMode;
    },
    uploadFile() {
      const file = this.$refs.file.files[0];
      this.file = this.$refs.file.files[0];
      if (file) {
        let img = new Image();
        img.onload = () => {
          let fr = new FileReader();
          fr.onload = (() => {
              return (e) => {
                this.preview_file = e.target.result;
                this.$bus.$emit('changeAvatar', e.target.result);
              };
          })(file);
          fr.readAsDataURL(file);
        };
        img.src = URL.createObjectURL(file);
        if (!(file.type.includes('jpeg') || file.type.includes('png') || file.type.includes('webp'))) {
          console.error('Загрузить можно только файлы с расширением jpeg, jpg, png!');
        }
      } else {
        return console.error('Во время загрузки файла произошла ошибка!');
      }
    },
    saveData() {
      this.pending = true;

      if (this.user.old && this.user.old === this.user.new) {
        this.pending = false;
        return console.error('Пароли не должны совпадать!');
      }
      if (this.user.replace !== this.user.new) {
        this.pending = false;
        return console.error('Пароли не совпадают!');
      }

      Axios
        .put(`/api/user`, this.user, {
          headers: {
            token: this.$store.getters.token
          }
        })
        .then(res => {
          this.pending = false;
          this.$bus.$emit('showNotification', {message: 'Настройки профиля успешно сохранены!', status: 'success'});
          this.changeEditMode();
          if (this.file) {
            const formData = new FormData();
            formData.append('recfile', this.file);
            Axios
              .post(`/api/user/avatar`, formData, {
                headers: {
                  token: this.$store.getters.token,
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(res => {
                
              })
              .catch(err => {
                console.error(err.response);
              })
          }
        })
        .catch(err => {
          console.error(err.response);
          this.pending = false;
          this.$bus.$emit('showNotification', {message: err.response.data, status: 'danger'});
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.block {
  padding: 50px 30px;
  height: fit-content;
}
.left {
  flex-basis: 300px;
  height: 250px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  .avatar {
    cursor: pointer;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: rgb(235, 235, 235);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
.right {
  flex-grow: 1;
  .profile__info {
    gap: 20px;
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 55px);
    margin-bottom: 30px;
  }
  .change__password {
    width: 49.2%;
    .title {
      font-weight: 500;
      margin-bottom: 20px;
      color: #00325C;
    }
    .input__block {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  button {
    height: 40px;
    width: 250px;
    margin-top: 20px;
  }
}
</style>